<template>
    <v-card
        :loading="!loaded"
    >
        <v-toolbar
                dark
                color="primary"
        >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="item.id" dark text @click="save">{{ trans('buttons.common.save') }}</v-btn>
                <v-btn v-else dark text @click="save">{{ trans('buttons.common.create') }}</v-btn>
                <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text v-if="loaded">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-file-input
                            show-size
                            accept="image/png, image/jpeg"
                            :placeholder="trans('fields.common.offerLogo')"
                            @change="logoChange"
                            :loading="logoLoading"
                        ></v-file-input>
                        <v-img
                            v-if="item && item.logoUrl"
                            contain
                            max-width="100%"
                            :src="item.logoUrl"
                            v-model="logoFile"
                        ></v-img>
                        <v-text-field
                            v-model="item.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                        <v-select
                            :items="options.languages"
                            :label="trans('fields.common.language')"
                            v-model="item.language_id"
                            :error-messages="errors.language_id"
                            @input="clearError('language_id')"
                        ></v-select>
                        <v-select
                            :items="options.countries"
                            :label="trans('fields.common.countries')"
                            :multiple="true"
                            v-model="item.countriesArr"
                            :error-messages="errors.countriesArr"
                            @input="clearError('countriesArr')"
                        ></v-select>
                        <v-select
                            :items="options.offerTypes"
                            :label="trans('fields.common.offerType')"
                            v-model="item.type_id"
                            @change="setDefaultFields"
                            :error-messages="errors.type_id"
                            @input="clearError('type_id')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="8" v-if="item.type_id">
                        <v-row>
                            <v-col
                                v-for="(field, itemName) in typeFields"
                                :key="itemName"
                                style="min-width: 300px;"
                            >
                                <offer-field :item="field" :item-name="itemName" :model="item" :languageLocales="options.languageLocalesKeyed" ></offer-field>
                            </v-col>
                        </v-row>


                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="item.id" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "OfferForm",
        mixins: [formValidate],
        components: {
            OfferField: () => import('@/components/commonComponents/OfferField'),
        },
        props: {
            itemId: {
                type: [Number, String, null, undefined],
            },
        },
        data(){
            return {
                showRoute: 'user.offers.show',
                storeRoute: 'user.offers.store',
                updateRoute: 'user.offers.update',
                item: {
                    id: this.itemId,
                    label: "",
                    language_id: null,
                    type_id: null,
                    fields: {},
                    countries: [],
                    countriesArr: [],
                    logoUrl: null,
                    logo_path: null,
                },
                error: null,
                options: null,
                loaded: false,

                logoFile: null,
                logoLoading: false,

            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                if (this.item.id) {
                    axios.get(this.route(this.showRoute, this.item.id)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    this.loadOptions();
                }
            },
            loadOptions(){
                axios.post(this.route('user.options'), {
                    languages: true,
                    countries: true,
                    offerTypes: true,
                }).then( response => {
                    this.options = response.data;
                    this.loaded = true;
                    if (this.item.type_id){
                        this.setDefaultFields(this.item.type_id);
                    }
                })
            },
            logoChange(file){
                if (file){
                    this.logoLoading = true;
                    let reader = new FileReader();
                    reader.onload = e => {
                        console.log(e);
                        this.item.logoUrl = e.target.result;
                    };
                    reader.readAsDataURL(file);
                    let formData = new FormData();
                    formData.append('file', file);
                    axios.post( this.route('user.offers.uploadLogo'),
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((response) => {
                        this.item.logo_path = response.data.logo_path;
                    }).catch(() => {
                        console.log('FAILURE!!');
                    }).then( () => {
                        this.logoLoading = false;
                    });
                } else {
                    this.item.logoUrl = null;
                }
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route(this.storeRoute), this.item).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                } else {
                    axios.post(this.route(this.updateRoute, this.item.id), this.item).then(() => {
                        console.log('save success');
                        this.$emit('saved');
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                        this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                    })
                }
            },
            setDefaultFields(offerTypeId){
                let defaultFields = {};
                let data = this.options.offerTypesFields[offerTypeId];
                _.defaultsDeep(defaultFields, data.dummy);
                _.mergeWith(defaultFields, this.item.fields, (objValue, srcValue) => {
                    if (srcValue === null) return objValue;
                });

                this.item.fields = defaultFields;
            },
        },
        computed: {
            typeFields() {
                let typeFields = {};
                if (this.item.type_id){
                    let data = this.options.offerTypesFields[this.item.type_id];
                    _.defaultsDeep(typeFields, data.fields);
                    _.defaultsDeep(typeFields, data.dummy);
                    _.defaultsDeep(typeFields, this.item.fields);
                }
                return typeFields;
            },

            formTitle() {
                return !this.item.id
                    ? this.trans('pages.offer.newDialogTitle')
                    : this.trans('pages.offer.editDialogTitle');
            },
        }
    }
</script>
